<template>
	<div>
		<h2>{{ $t('tiers.menu_coordonnees') }}</h2>

		<div class="box">
			<p>
				<label>{{ $t('tiers.adresse') }}</label> : 
				{{
					[
						contact.contact_address1,
						contact.contact_address2,
						`${contact.contact_postalcode} ${(contact.contact_town || '').toUpperCase()}`.trim(),
						country
					].filter(Boolean).join(', ')
				}}
			</p>
			
			<Phone ref="phoneComponent" :contact_id="contact.contact_id"></Phone>

			<p v-if="contact.contact_mail">
				<label>{{ $t('tiers.email') }}</label> : <a :href="'mailto:'+contact.contact_mail">{{ contact.contact_mail }}</a>
			</p>

			<div class="text-center">
				<button class="btn btn-primary btn-rounded mr-2" @click.prevent="edit_contact">{{ $t('global.modifier') }}</button>
				<button class="btn btn-primary btn-rounded" @click.prevent="addTiersFromContact">{{ $t('global.add_tiers_from_contact') }}</button>
				<ContactForm />
			</div>
		</div>
		<TiersModal ref="tiers_modal" from="contact"/>
	</div>
</template>

<script type="text/javascript">
import { EventBus } from 'EventBus'
import Vue from 'vue'
import Tools from '@/mixins/Tools.js'
import TableAction from '@/mixins/TableAction.js'
import Tiers from "@/mixins/Tiers.js"


import Countries from '@/assets/lang/countries/countries'

import Phone from '@/components/Tiers/Phone'

export default {
    name: 'ContactCoordonnees',
    mixins: [Tools, TableAction, Tiers],
    props: {
        contact: { type: Object, default: null},
        edit: { type: Boolean, default: true }
    },
	data() {
		return {
			processing: false,
			text_question: this.getTrad("modal.tiers_duplication.suppression"),
		}
	},

    methods: {
        edit_contact() {
        	this.goToEditContact([this.contact])
        },
		async addTiersFromContact() {
			this.$refs.tiers_modal.addOrEdit(this.contact)
		}
    },

    computed: {
        country(){
        	return Countries[Vue.i18n.locale()].label[this.contact.contact_country]
        }
    },

    components: {
		ContactForm : () => import('@/components/Contact/ContactForm'),
		TiersModal: () => import('@/components/Tiers/Form'),
		Phone
    }
}
</script>
